<template>
  <div class="container">
    <div class="reg-bg">
      <span class="bg-text bg-text-1" style="vertical-align: top;">上传简历</span><div class="reg-png"></div>
    </div>
    <div class="reg-pc-background"></div>
    <div class="reg-form-container">
      <div class="title">上传简历</div>
      <div class="text-info">
        为了更高效的安排后续面试，同时节省您在面试现场进行登记的时间，建议您提前上传好本人最新的个人简历(<span style="font-weight:bold;">Word/PDF格式</span>)。
      </div>
      <div class="text-info text-info-last">
        我们将对您的简历保密，不会透漏给任何第三方公司或机构。我们承诺您的简历只会被用于本公司的招聘面试事宜。
      </div>
      <div class="subtitle">提交您的简历，让我们更好的了解您。</div>
      <div class="form-item register-input">
        <div class="form-item-label">您的姓名:</div>
        <input class="register-input" type="text" v-model="form.name" placeholder="您的姓名"/>
        <span v-if="nameTip" class="tip">{{ nameTip }}</span>
      </div>
      <div class="form-item register-input">
        <div class="form-item-label">您的身份证号码:</div>
        <input class="register-input" type="number" v-model="form.id" placeholder="您的身份证号码"/>
        <span v-if="idTip" class="tip">{{ idTip }}</span>
      </div>
      <div class="form-item register-input">
      <div class="form-item-label">您本次应聘的岗位:</div>
        <input class="register-job" type="text" v-model="form.job" placeholder="您的本次应聘岗位"/><label class="input-file" for="file"><span class="upload">上传您的个人简历</span></label>
        <span v-if="jobTip" class="tip">{{ jobTip }}</span>
      </div>
      <div class="file-name file-name-phone" v-if="form.file">{{ form.file.name }}</div>
    </div>
    <div class="reg-pc-submit-wrap">
      <label class="input-file-pc" for="file"><span class="upload">上传您的个人简历</span></label>
      <button class="join-btn-submit reg-submit" :disabled="loading" @click="submitForm"><i v-if="loading" class="iconfont icon-loading"/>提交</button>
      <div class="file-name file-name-pc" v-if="form.file">{{ form.file.name }}</div>
    </div>
    <div class="reg-submit-wrap">
      <button class="join-btn-submit" :disabled="loading" @click="submitForm"><i v-if="loading" class="iconfont icon-loading"/>提交</button>
    </div>
    <div class="divide"></div>
    <input id="file" ref="file" name="file" @change="handleFileChange" class="upload-input" type="file" :accept="mineType"/>
  </div>
</template>
<script>
import { uploadInfo } from '@/http/api.js'
export default {
  data(){
    return{
      form: {
        name: '',
        id: '',
        job: '',
        file: ''
      },
      nameTip: '',
      idTip: '',
      jobTip: '',
      loading: false,
      mineType: 'application/msword,application/kswps,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  },
  methods:{
    checkName(){
      const name = this.form.name.trim()
      if(!name){
        this.nameTip = '名称不能为空'
        return false
      }else{
        this.nameTip = ''
        return true
      }
    },
    checkIdCard(){
      const id = this.form.id.trim()
      const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if(id){
        // todo 正则验证身份证号
        if(reg.test(id)){
          this.idTip = ''
          return true
        }else{
          this.idTip = '身份证号码格式不正确'
        }
      }else{
        this.idTip = '请您输入身份证号码'
        return false
      }
    },
    checkJob(){
      const job = this.form.job.trim()
      if(job){
        this.jobTip = ''
        return true
      }else{
        this.jobTip = '请您输入本次应聘岗位'
        return false
      }
    },
    getFileType(str){
      let a = str.split('.')
      let l = a.length
      return a[l-1]
    },
    checkFile(_file){
      const file = _file
      if(!file){
        this.$toast.warning('简历未上传')
        return false
      }else{
        let ftype = this.getFileType(file.name)
        if(['docx','doc','pdf'].indexOf(ftype) === -1){
          this.$toast.warning('简历必须是word或者pdf格式')
          return false
        }else{
          return true
        }
      }
    },
    handleFileChange(e){
      const file = e.target.files[0]
      if(this.checkFile(file)){
        this.form.file = file
      }
      this.$refs.file.value = null
    },
    
    submitForm(){
      if(this.checkName() && this.checkIdCard() && this.checkJob() && this.checkFile(this.form.file)){
        this.loading = true
        uploadInfo({
          file: this.form.file, name: this.form.name, idCard: this.form.id, categoryId: this.form.job
        }).then(res => {
          this.loading = false
          const { data } = res
          if(data.status === 200){
            this.form.file = ''
            this.$router.push('/success')
            sessionStorage.setItem('phone', data['data'])
          }else{
            this.$toast.warning(data.message)
          }
        }).catch(err=>{
          // console.log(err.response, 'err')
          this.loading = false
          if(err.response){
            this.$toast.warning(err.response.data.message)
          }else{
            this.$toast.warning('网络或服务异常!')
          }
        })
      }
    }
  }
}
</script>